<template>
  <div class="channel overflow">
    <div class="sub-menu">
      <div class="menu-it" v-for="(item, index) in subMenuList" :key="index" @click="changeMenu(item.key)"
        :class="{ cur: current == item.key }">
        {{ item.name }}
      </div>

    </div>
    <div class="view-content">
      <keep-alive>
        <component ref="components" v-bind:is="currentTabComponent" @changeMenu="changeMenu"></component>
      </keep-alive>
     <!-- 蒙层start -->
     <div class="agentMask" v-if="isShowMask"></div>
     <!-- 蒙层end -->
    </div>
     
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import api from '../../http/api';
  import { MessageBox, } from 'element-ui';
  import { getDays, nowDataMethod } from '../../utils/index'
  export default {
    components: {
      invitationList: () => import('../components/invitationList.vue'),
      invitationAgentList: () => import('../components/invitationAgentList.vue'),
      contactInfo: () => import('../components/contactInfo.vue'),
      myInvitationCode: () => import('../components/myInvitationCode.vue')
    },
    computed: {
      ...mapState(['agentSetting']), //
    },
    data() {
      return {
        isShowMask: false,//不是代理商的时候不可操作
        agentInfo: '',//代理商信息
        current: "invitationList",
        currentTabComponent: "invitationList",
        subMenuList: [
          {
            key: "invitationList",
            name: "邀请列表",
          },
          {
            key: "invitationAgentList",
            name: "代理列表",
          },
          {
            key: "contactInfo",
            name: "我的联系方式",

          },
          {
            key: "myInvitationCode",
            name: "我的邀请码",
          },
        ],
      };
    },
    activated() {
      this.getInfo();
    },
    created() {

    },
    methods: {
      getInfo() {
        let that = this;
        api.getAgentContact().then(res => {
          that.$store.commit("currentSet", res.response);
          if (res.code == 200) {
            if (res.response.id) {
              that.isShowMask = false;
            } else {
              that.isShowMask = true;
              that.tipsMethod();
            }
          }
        })
      },
      //不是代理商的提示
      tipsMethod(){
        MessageBox.alert(this.agentSetting.open_agent_tips, '温馨提示', {
                confirmButtonText: '确定',
                callback: action => { }
              })
      },
      //切换菜单
      async changeMenu(key) {
        if(this.isShowMask){
          this.tipsMethod();
        }
        this.current = key;
        this.currentTabComponent = key;
      },
    },
  };
</script>
<style lang="less" scoped>
  .agentMask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 100;
  }

  .channel {
    height: 100%;

    .sub-menu {
      display: flex;
      align-items: center;
      margin-bottom: 11px;

      .menu-it {
        min-width: 105px;
        height: 30px;
        line-height: 30px;
        background: linear-gradient(0deg, #d8d8d8, #f7f7f7);
        padding: 0 10px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        margin-right: 14px;
        text-align: center;
        cursor: pointer;
      }

      .cur {
        background: linear-gradient(180deg, #3f90f9, #314bfb);
        color: #ffffff;

        /deep/ .el-select .el-input__inner {
          background: linear-gradient(180deg, #3f90f9, #314bfb);
          color: #ffffff;
        }

        /deep/ .el-input__icon {
          color: #fff;
        }
      }
    }

    .view-content {
    position:relative;
      /* border: 1px solid #dcdcdc; */
      height: calc(100% - 41px);
      /* max-height: 100%; */
      /* padding: 18px 24px; */
    }
  }

  .selectDate {
    padding: 0 !important;
    background: transparent !important;

    /deep/ .el-select {
      .el-input__inner {
        border: none;
        width: 120px;
        background: linear-gradient(0deg, #d8d8d8, #f7f7f7);
        border-radius: 0;
        height: 30px;
        line-height: 30px;
        color: #000;
      }

      .el-input__icon {

        line-height: 30px;
      }

      .el-input.is-focus {
        .el-input__inner {
          border: none !important;
        }
      }
    }
  }
</style>